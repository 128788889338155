import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// import "./map.css";

const companyLogo = "assets/img/map.svg";

const MapWithAnimatedMarker = () => {
  const positions = [
    {
      position: [37.9838, 23.7275],
      label: "Athens",
      description:
        "Athens is the capital city of Greece. It is known for its historical landmarks such as the Acropolis and Parthenon.",
      image: "https://example.com/athens.jpg",
      link: "https://en.wikipedia.org/wiki/Athens",
    },
    {
      position: [40.6401, 22.9444],
      label: "Thessaloniki",
      description:
        "Thessaloniki is Greece's second-largest city and a major port city. It is famous for its vibrant cultural scene and historical sites.",
      image: "https://example.com/thessaloniki.jpg",
      link: "https://en.wikipedia.org/wiki/Thessaloniki",
    },
    {
      position: [38.2466, 21.7346],
      label: "Patras",
      description:
        "Patras is a port city in western Greece. It is known for its lively festivals and historical architecture.",
      image: "https://example.com/patras.jpg",
      link: "https://en.wikipedia.org/wiki/Patras",
    },
    {
      position: [35.3396, 25.1333],
      label: "Heraklion",
      description:
        "Heraklion is the largest city on the island of Crete, known for the Minoan Palace of Knossos and its rich history.",
      image: "https://example.com/heraklion.jpg",
      link: "https://en.wikipedia.org/wiki/Heraklion",
    },
    {
      position: [39.0742, 21.8243],
      label: "Delphi",
      description:
        "Delphi is an archaeological site in Greece, famous for the ancient Oracle of Delphi and its stunning mountain scenery.",
      image: "https://example.com/delphi.jpg",
      link: "https://en.wikipedia.org/wiki/Delphi",
    },
    {
      position: [37.9534, 23.6066],
      label: "Kifisia",
      description:
        "Kifisia is a suburb of Athens known for its beautiful parks, upscale shopping, and historic mansions.",
      image: "https://example.com/kifisia.jpg",
      link: "https://en.wikipedia.org/wiki/Kifisia",
    },
    {
      position: [37.9684, 23.7806],
      label: "Piraeus",
      description:
        "Piraeus is the main port city of Athens, important for its maritime trade and historic significance.",
      image: "https://example.com/piraeus.jpg",
      link: "https://en.wikipedia.org/wiki/Piraeus",
    },
  ];

  const customIcon = L.divIcon({
    html: `<img src="${companyLogo}" alt="Company Logo" style="width: 32px; height: 32px;" />`,
    className: "custom-div-icon custom-marker",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <MapContainer
      center={positions[0].position} // Center the map on Athens
      zoom={10}
      style={{ height: "65vh", width: "100%" }}
      scrollWheelZoom={false}
      className="map-container"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {positions.map((loc, index) => (
        <Marker key={index} position={loc.position} icon={customIcon}>
          <Popup>
            <div style={{ width: "200px" }}>
              <h3>{loc.label}</h3>
              <img
                src={loc.image}
                alt={`${loc.label} view`}
                style={{ width: "100%", height: "auto" }}
              />
              <p>{loc.description}</p>
              <a href={loc.link} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </Popup>
        </Marker>
      ))}
      <Circle
        center={positions[0].position} // Center the circle on Athens
        radius={500} // Adjust the radius of the circle
        color="blue" // Circle color
        fillColor="#blue" // Fill color of the circle
        fillOpacity={0.2} // Fill opacity
      >
        <Popup>
          <h3>Welcome to Athens!</h3>
          <p>
            This is the capital city of Greece, known for its rich history and
            culture.
          </p>
        </Popup>
      </Circle>
    </MapContainer>
  );
};

export default MapWithAnimatedMarker;
